import React from 'react';
import ReactDOM from 'react-dom/client';
import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom"
import App from './App';
import Login from './components/Login';
import Register from './components/Register';
import PersonalInfoForm from './components/PersonalInfoForm';
import ProfessionalInfoForm from './components/ProffessionalInfoForm';
import Authenticate from './components/Authenticate';
import Logout from './components/Logout';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
      <Routes>
          <Route path="/" element={<App />}>
              <Route index element={<Login />} />
              <Route path="register" element={<Register />}>
                <Route index element={<PersonalInfoForm />} />
                <Route path="step-2" element={<ProfessionalInfoForm />} />
              </Route>
          </Route>
          <Route path="/authenticate" element={<Authenticate />} />
          <Route path="/logout" element={<Logout />} />
      </Routes>
  </BrowserRouter>
);