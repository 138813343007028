import React, { useEffect, useState } from "react";
import loginBg from "../assets/images/login-bg.png"

import { useForm } from "react-hook-form";
import Links from "./Links";
import { useNavigate, useSearchParams } from "react-router-dom";
import _ from "lodash";
import { Modal } from "antd";

const Login = () => {

    const navigate = useNavigate()
    const [ urlParams ] = useSearchParams()

    const [showError, setShowError] = useState("")
    const [passwordOpen, setPasswordOpen] = useState(false);
    const [otpError, setOtpError] = useState('');
    const [passwordReset, setPasswordReset] = useState(false);
    const [samePasswordError, setSamePasswordError] = useState(false);
    const [loginOTP, setLoginOTP] = useState(false);
    const [loginOTPModal, setLoginOTPModal] = useState(false);
    const [verifyingOTP, setVerifyingOTP] = useState(false);
    const [loginLoader, setLoginLoader] = useState(false);

    const { register, handleSubmit, formState: { errors }, trigger, getValues } = useForm();
    const {register:otpRegister,handleSubmit:otpSubmit} = useForm()
    const {register:resetPasswordRegister,handleSubmit:resetPasswordSubmit, getValues:resetPasswordGetValues} = useForm()

    useEffect(() => {
        let mounting = false
        if(!mounting){
            document.title = "Login - Saira Jobs"
        }
        return () => {
            mounting = true
        }
    }, [])

    const authenticate = async data => {
        setShowError("")
        /* console.log(data); */
        setLoginLoader(true)
        const u = new URLSearchParams(data).toString();
        if(loginOTP){
            const loginURL = await fetch(`https://sairajobs.com/email/otp-login?${u}`)
            const loginProcessData = await loginURL.json()
            console.log(loginProcessData)
            setLoginLoader(false)
            if(loginProcessData.status === "success"){
                setLoginOTPModal(true)
            } else {
                setShowError("Something went wrong! Please try again after sometime.")
            }
        } else {
            const loginURL = await fetch(`https://api.sairajobs.com/login?${u}`)
            const loginProcessData = await loginURL.json()
            setLoginLoader(false)
            if(loginProcessData.token){
                localStorage.setItem('token',loginProcessData.token)
                let redirectUrl = "https://sairajobs.com/portal/"
                if(!_.isEmpty(urlParams.get('redirectTo'))){
                    redirectUrl = `${urlParams.get('redirectTo')}`
                }
                navigate(`/authenticate?redirectTo=${redirectUrl}`)
            } else {
                setShowError(loginProcessData.message)
            }
        }
    }

    const otpauth = (data) => {
        console.log(data)
        setVerifyingOTP(true)
        fetch(`https://api.sairajobs.com/verify-email?email=${getValues("email")}&type=verify&otp=${_.values(data).join("")}`)
        .then((res) => res.json())
        .then((res) => {
            setVerifyingOTP(false)
            if(res.result.token){
                localStorage.setItem('token',res.result.token)
                let redirectUrl = "https://sairajobs.com/portal/"
                if(!_.isEmpty(urlParams.get('redirectTo'))){
                    redirectUrl = `${urlParams.get('redirectTo')}`
                }
                navigate(`/authenticate?redirectTo=${redirectUrl}`)
            } else {
                setShowError(res.result.message)
            }
        })
    }

    const resetPasswordSubmission = async (data) => {
        const submissionData = {...data}
        delete submissionData.cpassword
        console.log(submissionData)
        const u = new URLSearchParams(submissionData).toString();
        const fetchURL = await fetch(`https://sairajobs.in/api/change-password?${u}`)
        const fetchData = await fetchURL.json()
        if(fetchData.matchedCount === 1 && fetchData.matchedCount === 1){
            setPasswordOpen(false)
            setPasswordReset(false)
        }
        if(fetchData.matchedCount === 1 && fetchData.matchedCount === 0){
            setSamePasswordError(true)
        }
    }

    const handlepaste = (e) => {
        const pastedData = e.clipboardData.getData('text/plain');
        console.log(/^\d*$/.test(pastedData))
        console.log(_.size(pastedData) === 6)
        if(_.size(pastedData) === 6 && /^\d*$/.test(pastedData)){
            _.forEach(pastedData.split(""),function(val,index){
                document.querySelectorAll(".input-outer-wrap input")[index].value = val
                document.querySelectorAll(".input-outer-wrap input")[index].focus()
            })
        } else {
            return
        }
    }

    const acceptOnlyNum = (e) => {
        const inputValue = e.target.value;
        if (!/^\d*$/.test(inputValue)) {
            e.target.value = '';
            return;
        }
    }

    const nextStep = (num, event) => {
        if(!["1","2","3","4","5","6","7","8","9","0"].includes(event.key)){
            return
        }
        if(event.key === "Backspace" && num !== 1){
            num = num === 0 ? 6 : num
            document.querySelectorAll(".input-outer-wrap input")[num - 2].focus()
            document.querySelectorAll(".input-outer-wrap input")[num - 2].select()
            return
        }
        if(event.key === "Backspace" && num === 1){
            document.querySelectorAll(".input-outer-wrap input")[0].focus()
            document.querySelectorAll(".input-outer-wrap input")[0].select()
            return
        }
        if(num === 0){
            document.querySelector(".verifi-popup-wrap .btn").focus()
            return
        }
        document.querySelectorAll(".input-outer-wrap input")[num].focus()
        document.querySelectorAll(".input-outer-wrap input")[num].select()
    }

    const verifyOTP = (data) => {
        // console.log(_.values(data).join(""))

        setVerifyingOTP(true)
        fetch(`https://api.sairajobs.com/verify-email?email=${getValues("email")}&type=verify&otp=${_.values(data).join("")}`)
        .then((res) => {
            if(res.error){
                setOtpError("Invalid OTP please try again")
                return
            }
            setPasswordReset(true)
            setVerifyingOTP(false)
        })
    }

    const resetPassword = () => {
        trigger("email")
        .then((res) => {
            if(res){
                setPasswordOpen(true)
            }
        })
    }

    const otpLogin = () => {
        setLoginOTP(e => {
            return !e
        })
    }

    const emailpattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,12})+$/ // eslint-disable-line no-useless-escape

    return (
        <div className="login-wrap" style={{backgroundImage: `url(${loginBg})`}}>
            <div className="content">
                <h2 className="title">Sign in and Begin Your Journey</h2>
                <div className="register">Don't have an account? <Links target="/register" content="Register Now" /></div>
                <div className="find-job">
                    <form onSubmit={handleSubmit(authenticate)} className="noCol" aria-label="Login Form">
                        <div className={errors.email ? "inputHolder has-error" : "inputHolder"}>
                            <input type="text" placeholder=" " className="txt" id="usremail" {...register("email", { required: "Please enter your email", pattern: {value:emailpattern, message:"Please Provide a valid Email ID"} })} aria-label="Email ID"/>
                            <label htmlFor="usremail" className="tag" aria-hidden>Email ID</label>
                            <span className="form-error" aria-live="assertive" role="alert">{errors.email && <>{errors.email.message}</>}</span>
                        </div>
                        {!loginOTP && <div className={errors.password ? "inputHolder has-error" : "inputHolder"}>
                            <input type="password" placeholder=" " className="txt" id="usrpassword" {...register("password", {required: "Please enter your Password", minLength: {value:8,message:'The password should have minimum 8 characters'} })} aria-label="Password" />
                            <label htmlFor="usrpassword" className="tag" aria-hidden>Password</label>
                            <span className="form-error" aria-live="assertive" role="alert">{errors.password && <>{errors.password.message}</>}</span>
                        </div>}
                        <div className="inputHolder has-error" style={{minHeight:"1px"}}><span className="form-error" aria-live="assertive" role="alert">
                            {
                                !_.isEmpty(showError) && <>{showError}</>
                            }
                        </span></div>
                        <div className="inputHolder btnwrapper">
                            {!loginOTP && <button type="button" className="forgot" onClick={() => resetPassword()}>Forgot Password?</button>}
                            {/* <button type="submit" className="btn">Login</button> */}
                            <button type="submit" className="btn" disabled={loginLoader}>{loginLoader ? <svg viewBox="0 0 50 50" className="btn-loader-2"><path d="M50 25C50 11.2 38.8 0 25 0S0 11.2 0 25h4.2C4.2 13.6 13.5 4.2 25 4.2S45.8 13.6 45.8 25H50z" style={{ fill: "#fff" }}><animateTransform accumulate="none" additive="replace" attributeName="transform" attributeType="XML" calcMode="linear" dur="1s" fill="remove" from="0 25 25" repeatCount="indefinite" restart="always" to="360 25 25" type="rotate" /></path></svg> : "Verify"}</button>
                            <button type="button"  className="otp-login" onClick={() => otpLogin()}>Use {!loginOTP ? "OTP" : "Password"} to Login</button>
                        </div>
                    </form>
                </div>
            </div>
            <Modal open={passwordOpen} footer={null} closable={false} maskStyle={{backgroundColor:"rgba(0,0,0,0.75)"}}>
            {!passwordReset && <form className="verifi-popup-wrap" id="verify" onSubmit={otpSubmit(verifyOTP)}>
                <h2>Check Your Email!</h2>
                <p>We have send 6 digit confirmation code to your email.<br />
                    Please enter the code before it expires.
                </p>
                <div className="input-outer-wrap">
                    <div className="input-wrap">
                        <input type="text" {...otpRegister("opt1")} className="input-num one" maxLength={1} onInput={(e) => acceptOnlyNum(e)} onKeyUp={(e) => nextStep(1,e)} onPaste={handlepaste} />
                        <input type="text" {...otpRegister("opt2")} className="input-num two" maxLength={1} onInput={(e) => acceptOnlyNum(e)} onKeyUp={(e) => nextStep(2,e)} />
                        <input type="text" {...otpRegister("opt3")} className="input-num three" maxLength={1} onInput={(e) => acceptOnlyNum(e)} onKeyUp={(e) => nextStep(3,e)} />
                    </div>
                    <div className="txt"></div>
                    <div className="input-wrap">
                        <input type="text" {...otpRegister("opt4")} className="input-num one" maxLength={1} onInput={(e) => acceptOnlyNum(e)} onKeyUp={(e) => nextStep(4,e)} />
                        <input type="text" {...otpRegister("opt5")} className="input-num two" maxLength={1} onInput={(e) => acceptOnlyNum(e)} onKeyUp={(e) => nextStep(5,e)} />
                        <input type="text" {...otpRegister("opt6")} className="input-num three" maxLength={1} onInput={(e) => acceptOnlyNum(e)} onKeyUp={(e) => nextStep(0,e)} />
                    </div>
                </div>
                <p><b>Resend Code in 20 Seconds</b></p>
                <p>Keep this window open while checking for your code.<br />
                    Remember to try your spam folder!
                </p>
                <div className="inputHolder has-error" style={{minHeight:"1px"}}><span className="form-error" aria-live="assertive" role="alert">
                {
                    !_.isEmpty(otpError) && <>{otpError}</>
                }
                </span></div>
                <div className="btn-wrap justify-content-center">
                    {/* <button className="btn">Verify</button> */}
                    <button type="submit" className="btn" disabled={verifyingOTP}>{verifyingOTP ? <svg viewBox="0 0 50 50" className="btn-loader-2"><path d="M50 25C50 11.2 38.8 0 25 0S0 11.2 0 25h4.2C4.2 13.6 13.5 4.2 25 4.2S45.8 13.6 45.8 25H50z" style={{ fill: "#fff" }}><animateTransform accumulate="none" additive="replace" attributeName="transform" attributeType="XML" calcMode="linear" dur="1s" fill="remove" from="0 25 25" repeatCount="indefinite" restart="always" to="360 25 25" type="rotate" /></path></svg> : "Verify"}</button>
                    <button type="button" className="btn red secondary" onClick={() => setPasswordOpen(false)}>Cancel</button>
                </div>
            </form>}
            {
                passwordReset && <form onSubmit={resetPasswordSubmit(resetPasswordSubmission)} className="verifi-popup-wrap" noValidate>
                    <h2>Reset Password</h2>
                    <div className={errors && errors.password ? "inputHolder has-error" : "inputHolder"}>
                        <input id="password" type="password" placeholder=" " className="txt" {...resetPasswordRegister('password', { minLength: {value:8,message:'The password should have minimum 8 characters'} })} aria-label="New Password" />
                        <label htmlFor="password" className="tag" aria-hidden>New Password</label>
                        <span className="form-error" aria-live="assertive" role="alert">
                            {
                                (errors && errors.password) && <>{errors.password.message}</>
                            }
                        </span>
                    </div>
                    <div className={errors && errors.cpassword ? "inputHolder has-error" : "inputHolder"}>
                        <input id="cnfPassword" type="password" placeholder=" " className="txt" {...resetPasswordRegister('cpassword', { validate: (value) => resetPasswordGetValues("password") === value || "Password doesn't match" })} aria-label="Confirm Password"/>
                        <label htmlFor="cnfPassword" className="tag" aria-hidden>Confirm Password</label>
                        <span className="form-error" aria-live="assertive" role="alert">{
                            (errors && errors.cpassword) && <>{errors.cpassword.message}</>
                        }</span>
                    </div>
                    <div className="inputHolder has-error" style={{minHeight:"1px"}}><span className="form-error" aria-live="assertive" role="alert">{
                        samePasswordError && <>Please provide a password that is not similar to your previous password</>
                    }</span></div>
                    <div className="inputHolder btn-wrap justify-content-center">
                        <button className="btn">Change Password</button>
                    </div>
                </form>
            }
            </Modal>
            <Modal open={loginOTPModal} footer={null} closable={false} maskStyle={{backgroundColor:"rgba(0,0,0,0.75)"}}>
                <form className="verifi-popup-wrap" id="verify" onSubmit={otpSubmit(otpauth)}>
                    <h2>Check Your Email!</h2>
                    <p>We have send 6 digit confirmation code to your email.<br />
                        Please enter the code before it expires.
                    </p>
                    <div className="input-outer-wrap">
                        <div className="input-wrap">
                            <input type="text" {...otpRegister("opt1")} className="input-num one" maxLength={1} onInput={(e) => acceptOnlyNum(e)} onKeyUp={(e) => nextStep(1,e)} onPaste={handlepaste} />
                            <input type="text" {...otpRegister("opt2")} className="input-num two" maxLength={1} onInput={(e) => acceptOnlyNum(e)} onKeyUp={(e) => nextStep(2,e)} />
                            <input type="text" {...otpRegister("opt3")} className="input-num three" maxLength={1} onInput={(e) => acceptOnlyNum(e)} onKeyUp={(e) => nextStep(3,e)} />
                        </div>
                        <div className="txt"></div>
                        <div className="input-wrap">
                            <input type="text" {...otpRegister("opt4")} className="input-num one" maxLength={1} onInput={(e) => acceptOnlyNum(e)} onKeyUp={(e) => nextStep(4,e)} />
                            <input type="text" {...otpRegister("opt5")} className="input-num two" maxLength={1} onInput={(e) => acceptOnlyNum(e)} onKeyUp={(e) => nextStep(5,e)} />
                            <input type="text" {...otpRegister("opt6")} className="input-num three" maxLength={1} onInput={(e) => acceptOnlyNum(e)} onKeyUp={(e) => nextStep(0,e)} />
                        </div>
                    </div>
                    <p><b>Resend Code in 20 Seconds</b></p>
                    <p>Keep this window open while checking for your code.<br />
                        Remember to try your spam folder!
                    </p>
                    <div className="inputHolder has-error" style={{minHeight:"1px"}}><span className="form-error" aria-live="assertive" role="alert">
                    {
                        !_.isEmpty(otpError) && <>{otpError}</>
                    }
                    </span></div>
                    <div className="btn-wrap justify-content-center">
                        {/* <button className="btn">Verify</button> */}
                        <button type="submit" className="btn" disabled={verifyingOTP}>{verifyingOTP ? <svg viewBox="0 0 50 50" className="btn-loader-2"><path d="M50 25C50 11.2 38.8 0 25 0S0 11.2 0 25h4.2C4.2 13.6 13.5 4.2 25 4.2S45.8 13.6 45.8 25H50z" style={{ fill: "#fff" }}><animateTransform accumulate="none" additive="replace" attributeName="transform" attributeType="XML" calcMode="linear" dur="1s" fill="remove" from="0 25 25" repeatCount="indefinite" restart="always" to="360 25 25" type="rotate" /></path></svg> : "Verify"}</button>
                        <button type="button" className="btn red secondary" onClick={() => setLoginOTPModal(false)}>Cancel</button>
                    </div>
                </form>
            </Modal>
        </div>
    )
}

export default Login