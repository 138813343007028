import React, { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import Header from './components/Header'
import Footer from './components/Footer'
import { IPContext } from './context/ip'
import a11yChecker from 'a11y-checker'


/**
 * App: main component of project
 * @returns
 */
const App = () => {
  const [ip, setIP]=useState('')
  useEffect(() => {
    if(process.env.NODE_ENV === 'development'){
        a11yChecker()
    }
    const getIp = async () => {
      const geoData = await fetch('https://api.sairajobs.com/system-info')
      const {ip} = await geoData.json()
      setIP(ip)
    }
    getIp()
  }, [])
  return (
    <IPContext.Provider value={ip}>
      <Header />
      <Outlet />
      <Footer />
    </IPContext.Provider>
  )
}

export default App
