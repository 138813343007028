import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

const Logout = () => {
    const navigate = useNavigate()
    useEffect(() => {
        const logOutUser = async () => {
            localStorage.removeItem("token")
            navigate("/")
        }
        logOutUser()
    },[navigate])
    return <>
    <div className="container">
        <div className="dflex alignMiddle justifyMiddle" style={{height:"100vh"}}>
            <div className="loader">
                <svg x="0" y="0" viewBox="0 0 46 40" >
                    <path style={{fill:"#77cfe2"}} d="M41 8c2.8 0 5 2.2 5 5v14c0 2.8-2.2 5-5 5s-5-2.2-5-5V13c0-2.8 2.2-5 5-5z"/>
                    <path style={{fill:"#77cfe2"}} d="M29 8c2.8 0 5 2.2 5 5v14c0 2.8-2.2 5-5 5s-5-2.2-5-5V13c0-2.8 2.2-5 5-5z"/>
                    <path style={{fill:"#77cfe2"}} d="M17 8c2.8 0 5 2.2 5 5v14c0 2.8-2.2 5-5 5s-5-2.2-5-5V13c0-2.8 2.2-5 5-5z"/>
                    <path style={{fill:"#77cfe2"}} d="M5 8c2.8 0 5 2.2 5 5v14c0 2.8-2.2 5-5 5s-5-2.2-5-5V13c0-2.8 2.2-5 5-5z"/>
                </svg>
            </div>
        </div>
    </div>
    </>
}

export default Logout