import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import PersonalInfoForm from "./PersonalInfoForm";
import ProfessionalInfoForm from "./ProffessionalInfoForm";
import { useNavigate } from "react-router-dom";

const Register = () => {
    const navigate = useNavigate()

    const { register:registerStepA, formState:{dirtyFields:dirtyFieldsStepA,errors:errorsStepA,isValid:isValidStepA}, trigger:triggerStepA, watch:watchStepA, getValues:getValuesStepA, control:controlStepA } = useForm({
        defaultValues: {
            userType: "",
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            cpassword: "",
            phoneNumber: ""
        }
    });
    const { register:registerStepB, handleSubmit:handleSubmitStepB, formState:{dirtyFields:dirtyFieldsStepB,errors:errorsStepB,isValid:isValidStepB}, trigger:triggerStepB, watch:watchStepB, getValues:getValuesStepB, setValue:setValueStepB,control:controlStepB, resetField:resetFieldStepB } = useForm({
        defaultValues: {
            employeeType: "Fresher",
            experienceYears: 0,
            currentEmployment: "no",
            jobTitle: "",
            technicalSkills: []
        }
    });

    const [stepA,setStepA] = useState("0%")
    const [stepB,setStepB] = useState("0%")

    const [registrationLoader, setRegistrationLoader] = useState(false)
    const [duplicateEmail, setDuplicateEmail] = useState(false)

    const [currentEmail, setCurrentEmail] = useState("")
    const [emailVerified, setEmailVerified] = useState(false)

    const [registerStep, setRegisterStep] = useState(true)

    const watchAllFieldsStepA = watchStepA(['firstName','lastName','email','password','cpassword','phoneNumber'])
    const watchAllFieldsStepB = watchStepB(['jobTitle','technicalSkills'])

    useEffect(() => {
        let percentage = 0
        const total = _.size(watchAllFieldsStepA)
        const completedFields = _.size(_.omit(dirtyFieldsStepA,'userType'))
        percentage = (completedFields/total) * 100
        setStepA(`${percentage}%`)
    },[watchAllFieldsStepA,dirtyFieldsStepA,])

    useEffect(() => {
        let percentage = 0
        const total = _.size(watchAllFieldsStepB)
        const completedFields = _.size(_.omit(dirtyFieldsStepB,['employeeType', 'experienceYears','currentEmployment']))
        percentage = (completedFields/total) * 100
        setStepB(`${percentage}%`)
    },[watchAllFieldsStepB,dirtyFieldsStepB])

    useEffect(() => {
        let mounting = false
        if(!mounting){
            document.title = "Register - Saira Jobs"
        }
        return () => {
            mounting = true
        }
    }, [])

    const registerUser = async (data) => {
        setRegistrationLoader(true)
        const stepAData = getValuesStepA()
        if(!data.currentEmployment){
            data.currentEmployment = "no"
        }
        if(!data.experienceYears){
            data.experienceYears = 0
        }
        const userRegistration = {...stepAData,...data,verify:true}
        const formData = new FormData();
        _.mapKeys(userRegistration,(value,key) => {
            formData.append(key,value)
        })
        /* console.log("Registration Data ===> ",formData) */
        const fetchURL = await fetch(`https://api.sairajobs.com/register`,{
            method: "POST",
            body: formData
        })
        const fetchData = await fetchURL.json()
        if(fetchData.status){
            localStorage.setItem('token',fetchData.token)
            navigate("/authenticate")
        } else {
            // if(fetchData.error.includes("duplicate key error")){
                setRegistrationLoader(false)
            // }
        }
    }

    return (
        <div className="register-form-wrap">
            <div className="container">

                <div className="form-head">
                    <h2 className="title">Fill the form and unlock the gateway to Saira's extraordinary realm</h2>
                    <div className="form-info">
                        <div className="fill-wrap">
                            <div className="round active">1</div>
                            <p>Personal Information</p>
                            <div className={!_.isEmpty(errorsStepA) || duplicateEmail ? "gradient-fill error" : "gradient-fill active"} style={{overflow:"hidden"}}><span style={{ width: stepA }}></span></div>
                        </div>
                        <div className="fill-wrap">
                            <div className={!registerStep ? "round active" : "round start"}>2</div>
                            <p>Professional Information</p>
                            <div className={!_.isEmpty(errorsStepB) ? "gradient-fill error" : "gradient-fill active"}><span style={{ width: stepB }}></span></div>
                        </div>
                        <div className={registrationLoader ? "round active" : "round"}></div>

                    </div>
                </div>

                {/* <ol className="form-content"> */}
                {/* <Outlet context={{
                    registerStepA:registerStepA,
                    handleSubmitStepA:handleSubmitStepA,
                    watchStepA:watchStepA,
                    triggerStepA:triggerStepA,
                    getValuesStepA:getValuesStepA,
                    isValidStepA:isValidStepA,
                    errorsStepA:errorsStepA,
                    registerStepB:registerStepB,
                    handleSubmitStepB:handleSubmitStepB,
                    watchStepB:watchStepB,
                    triggerStepB:triggerStepB,
                    getValuesStepB:getValuesStepB,
                    setValueStepB:setValueStepB,
                    controlStepB:controlStepB,
                    isValidStepB:isValidStepB,
                    errorsStepB:errorsStepB
                }}/> */}
                {/* </ol>

                <div className="btn-wrap">
                    <button type="button" className="btn secondary">BacK</button>
                    <button type="submit" className="btn">save and continue</button>
                </div> */}

                { registerStep && <PersonalInfoForm registerStepA={registerStepA} watchStepA={watchStepA} triggerStepA={triggerStepA} isValidStepA={isValidStepA} errorsStepA={errorsStepA} setRegisterStep={setRegisterStep} getValuesStepA={getValuesStepA} setDuplicateEmail={setDuplicateEmail} controlStepA={controlStepA}  emailVerified={emailVerified}  setEmailVerified={setEmailVerified} currentEmail={currentEmail} setCurrentEmail={setCurrentEmail}/>}
                { !registerStep && <ProfessionalInfoForm registerStepB={registerStepB} handleSubmitStepB={handleSubmitStepB} watchStepB={watchStepB} triggerStepB={triggerStepB} getValuesStepB={getValuesStepB} setValueStepB={setValueStepB} controlStepB={controlStepB} isValidStepB={isValidStepB} errorsStepB={errorsStepB} setRegisterStep={setRegisterStep} resetFieldStepB={resetFieldStepB} registerUser={registerUser} registrationLoader={registrationLoader} watchStepA={watchStepA} />}

            </div>
        </div>
    )
}

export default Register