import _ from "lodash";
import { Select, Tooltip, Modal } from "antd";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import useCountdown from "../hooks/setCountdown";

const PersonalInfoForm = ({ registerStepA, errorsStepA, triggerStepA, isValidStepA, getValuesStepA, setRegisterStep, setDuplicateEmail, controlStepA, watchStepA, emailVerified, setEmailVerified, currentEmail, setCurrentEmail }) => {
    const [duplicateEmailError, setDuplicateEmailError] = useState(false)
    const [savingLoader,setSavingLoader] = useState(false)

    const {secondsLeft, start} = useCountdown()

    const [verifyemail,setVerifyEmail] = useState(false)
    const [verifyingOTP,setVerifyingOTP] = useState(false)
    const [otpError,setOtpError] = useState(false)

    const disability = [
        {
            disabled: true,
            value: "",
            label: "Select All Disabilities That Macthes You"
        },
        {
            value: "Blindness",
            label: "Blindness"
        },
        {
            value: "Low vision",
            label: "Low vision"
        },
        {
            value: "Leprosy Cured Persons",
            label: "Leprosy Cured Persons"
        },
        {
            value: "Hearing Impairment",
            label: "Hearing Impairment"
        },
        {
            value: "Locomotor Disability",
            label: "Locomotor Disability"
        },
        {
            value: "Dwarfism",
            label: "Dwarfism"
        },
        {
            value: "Intellectual Disability",
            label: "Intellectual Disability"
        },
        {
            value: "Mental Illness",
            label: "Mental Illness"
        },
        {
            value: "Autism Spectrum Disorder",
            label: "Autism Spectrum Disorder"
        },
        {
            value: "Cerebral Palsy",
            label: "Cerebral Palsy"
        },
        {
            value: "Muscular Dystrophy",
            label: "Muscular Dystrophy"
        },
        {
            value: "Chronic Neurological Conditions",
            label: "Chronic Neurological Conditions"
        },
        {
            value: "Specific Learning Disabilities",
            label: "Specific Learning Disabilities"
        },
        {
            value: "Multiple Sclerosis",
            label: "Multiple Sclerosis"
        },
        {
            value: "Speech and Language Disability",
            label: "Speech and Language Disability"
        },
        {
            value: "Thalassemia",
            label: "Thalassemia"
        },
        {
            value: "Hemophilia",
            label: "Hemophilia"
        },
        {
            value: "Sickle Cell Disease",
            label: "Sickle Cell Disease"
        },
        {
            value: "Acid Attack Victims",
            label: "Acid Attack Victims"
        },
        {
            value: "Parkinson's Disease",
            label: "Parkinson's Disease"
        }
    ]

    const proceed = async () => {
        setSavingLoader(true)
        setDuplicateEmailError(false)
        triggerStepA();
        const fetchURL = await fetch(`https://api.sairajobs.com/check-email?email=${getValuesStepA("email")}`)
        const fetchData = await fetchURL.json()
        if (fetchData.result.status) {
            setDuplicateEmailError(true)
            setDuplicateEmail(true)
            setSavingLoader(false)
        } else {
            if(emailVerified && currentEmail === getValuesStepA("email")){
                if (isValidStepA) {
                    setSavingLoader(false)
                    setRegisterStep(false)
                }
            } else {
                start(20)
                setVerifyEmail(true)
                const otpInfo = {
                    email: getValuesStepA("email")
                }
                const u = new URLSearchParams(otpInfo).toString();
                fetch(`https://sairajobs.com/email/verify-email?${u}`)
            }
        }
    }

    const handleKeyPress = (event) => {
        const keyCode = event.keyCode || event.which;
        const keyValue = String.fromCharCode(keyCode);
        const isShiftWithNumber = event.shiftKey && /^[0-9]+$/.test(keyValue);

        if (event.key === 'Backspace' || event.key === 'Delete' || event.key === 'Tab' || (event.ctrlKey && keyValue === 'c') || (event.ctrlKey && keyValue === 'a') || (event.ctrlKey && keyValue === 'v') || (event.ctrlKey && keyValue === 'x')) {
            return;
        }

        // Allow only numbers (0-9)
        if (!/^[0-9]+$/.test(keyValue) || isShiftWithNumber) {
            event.preventDefault();
        }
    };


    const {register:otpRegister,handleSubmit:otpSubmit} = useForm()

    const resendOTP = () => {
        start(20)
        const otpInfo = {
            email: getValuesStepA("email")
        }
        const u = new URLSearchParams(otpInfo).toString();
        fetch(`https://sairajobs.com/email/verify-email?${u}`)
    }

    const verifyOTP = (data) => {
        setVerifyingOTP(true)
        setOtpError(false)
        fetch(`https://api.sairajobs.com/verify-email?email=${getValuesStepA("email")}&type=verifynonuser&otp=${_.values(data).join("")}`)
        .then((res) => res.json())
        .then((res) => {
            setVerifyingOTP(false)
            if(res.result.success){
                setCurrentEmail(getValuesStepA("email"))
                if (isValidStepA) {
                    setEmailVerified(true)
                    setSavingLoader(false)
                    setRegisterStep(false)
                }
            } else {
                setOtpError(res.result.message)
            }
        })
    }

    const handlepaste = (e) => {
        const pastedData = e.clipboardData.getData('text/plain');
        // console.log(/^\d*$/.test(pastedData))
        // console.log(_.size(pastedData) === 6)
        if(_.size(pastedData) === 6 && /^\d*$/.test(pastedData)){
            _.forEach(pastedData.split(""),function(val,index){
                document.querySelectorAll(".input-outer-wrap input")[index].value = val
                document.querySelectorAll(".input-outer-wrap input")[index].focus()
            })
        } else {
            return
        }
    }

    const acceptOnlyNum = (e) => {
        const inputValue = e.target.value;
        if (!/^\d*$/.test(inputValue)) {
            e.target.value = '';
            return;
        }
    }

    const nextStep = (num, event) => {
        if(!["1","2","3","4","5","6","7","8","9","0"].includes(event.key)){
            return
        }
        if(event.key === "Backspace" && num !== 1){
            num = num === 0 ? 6 : num
            document.querySelectorAll(".input-outer-wrap input")[num - 2].focus()
            document.querySelectorAll(".input-outer-wrap input")[num - 2].select()
            return
        }
        if(event.key === "Backspace" && num === 1){
            document.querySelectorAll(".input-outer-wrap input")[0].focus()
            document.querySelectorAll(".input-outer-wrap input")[0].select()
            return
        }
        if(num === 0){
            document.querySelector(".verifi-popup-wrap .btn").focus()
            return
        }
        document.querySelectorAll(".input-outer-wrap input")[num].focus()
        document.querySelectorAll(".input-outer-wrap input")[num].select()
    }


    const emailpattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,12})+$/ // eslint-disable-line no-useless-escape
    const namePattern = /^[A-Za-z\s.']*$/ // eslint-disable-line no-useless-escape

    return <>
        <ol className="form-content">
            <li className="form-wrap">
                {/* <div className="num">1</div> */}
                <div className="inputHolder">
                    <div className="tag">I'm a</div>
                    <div className="radio-wrap">
                        <Tooltip title="By selecting this option, you will have access to view only job opportunities specific to Senior Citizens.">
                            <span className="label">
                                <label htmlFor="userType1">Senior Citizen</label>
                                <input type="radio" {...registerStepA("userType", { required: true })} id="userType1" value="Senior Citizen" />
                                <span className="checkmark"></span>
                            </span>
                        </Tooltip>
                        <Tooltip title="By selecting this option, you will see only job opportunities specific to Women.">
                            <span className="label">
                                <label htmlFor="userType2">Back to Work Woman</label>
                                <input type="radio" {...registerStepA("userType", { required: true })} id="userType2" value="Back to Work Woman" />
                                <span className="checkmark"></span>
                            </span>
                        </Tooltip>
                        <Tooltip title="By selecting this option, you will view only job openings related to PWD (Persons with Disabilities).">
                            <span className="label">
                                <label htmlFor="userType3">Specially Abled (PWD)</label>
                                <input type="radio" {...registerStepA("userType", { required: true })} id="userType3" value="Specially Abled (PWD)" />
                                <span className="checkmark"></span>
                            </span>
                        </Tooltip>
                        <span className="form-error" aria-live="assertive" role="alert">{(errorsStepA && errorsStepA.firstName) && "Please select a Category"}</span>
                    </div>
                </div>
            </li>

            <li className="form-wrap">
                {/* <div className="num">2</div> */}
                <div className="content min label-text-wrap">
                    <div className={errorsStepA && errorsStepA.firstName ? "inputHolder has-error" : "inputHolder"}>
                        <input type="text" placeholder=" " id="firstName" {...registerStepA("firstName", { required: true, pattern: namePattern })} className="txt" aria-label="First Name" />
                        <label className="tag" htmlFor="firstName" aria-hidden>First Name</label>
                        <span className="form-error" aria-live="assertive" role="alert">{(errorsStepA && errorsStepA.firstName) && "Please provide your First Name"}</span>
                    </div>
                </div>
            </li>

            <li className="form-wrap">
                {/* <div className="num">2</div> */}
                <div className="content min label-text-wrap">
                    <div className={errorsStepA && errorsStepA.lastName ? "inputHolder has-error" : "inputHolder"}>
                        <input type="text" placeholder=" " id="lastName" {...registerStepA("lastName", { required: true, pattern: namePattern })} className="txt" aria-label="Last Name"/>
                        <label className="tag" htmlFor="lastName" aria-hidden>Last Name</label>
                        <span className="form-error" aria-live="assertive" role="alert">{(errorsStepA && errorsStepA.lastName) && "Please provide your Last Name"}</span>
                    </div>
                </div>
            </li>

            {watchStepA("userType") === "Specially Abled (PWD)" && <li className="form-wrap">
                {/* <div className="num">2</div> */}
                <div className="content min label-text-wrap">
                    <div className={errorsStepA && errorsStepA.lastName ? "inputHolder select has-error" : "inputHolder select"}>
                        <Controller name="disability" control={controlStepA} rules={{ required: true }} render={
                            ({ field }) => <Select bordered={false} id="disability" style={{ width: "100%", height: "50px", borderBottom: "1px solid #B9B9B9" }} options={disability} size="large" suffixIcon={<svg viewBox="0 0 15 7" style={{ width: "13px", height: "13px" }}><path d="M7.5 7c-.1 0-.2 0-.3-.1l-7-6C0 .7-.1.4.1.2.3 0 .6-.1.8.1l6.7 5.7L14.2.1c.2-.2.5-.2.7.1.1.2.1.5-.1.7l-7 6c-.1 0-.2.1-.3.1z" style={{ fill: "#1d252c" }} /></svg>} listItemHeight={10} listHeight={250} dropdownMatchSelectWidth={false} optionFilterProp={"label"} dropdownStyle={{ backgroundColor: "#EFF1F8" }} {...field} placement={"bottomLeft"} mode="tags" tokenSeparators={[',']} maxTagCount="responsive" placeholder="Select All Disabilities That Macthes You" aria-label="Type of Disabilities" />
                        } />
                        <label htmlFor="disability" className="tag" aria-hidden>Type of Disabilities</label>
                        <span className="form-error" aria-live="assertive" role="alert">{
                            (errorsStepA && errorsStepA.disability) && "Please select your Disability"
                        }</span>
                    </div>
                </div>
            </li>
            }

            <li className="form-wrap">
                {/* <div className="num">3</div> */}
                <div className="content min label-text-wrap">
                    <div className={(errorsStepA && errorsStepA.email) || duplicateEmailError ? "inputHolder has-error" : "inputHolder"}>
                        <input type="email" placeholder=" " id="email" {...registerStepA("email", { required: true, pattern: emailpattern })} className="txt" aria-label="Email" />
                        <label className="tag" htmlFor="email" aria-hidden>Email</label>
                        <span className="form-error" aria-live="assertive" role="alert">{(errorsStepA && errorsStepA.email) && <>
                            Please provide a valid Email Address
                        </>
                        }</span>
                        <span className="form-error" aria-live="assertive" role="alert">{
                            duplicateEmailError && "This Email address already exist with us. Please Login using this email"
                        }</span>
                    </div>
                </div>
            </li>

            <li className="form-wrap">
                {/* <div className="num">4</div> */}
                <div className="content min label-text-wrap">
                    <div className={errorsStepA && errorsStepA.password ? "inputHolder has-error" : "inputHolder"}>
                        <input type="password" placeholder=" " id="password" {...registerStepA("password", { required:'Please provide a password', minLength: {value:8,message:'The password should have minimum 8 characters'} })} className="txt" aria-label="Password" />
                        <label className="tag" htmlFor="password" aria-hidden>Password</label>
                        <span className="form-error" aria-live="assertive" role="alert">{(errorsStepA && errorsStepA.password) && errorsStepA.password.message}</span>
                    </div>
                </div>
            </li>

            <li className="form-wrap">
                {/* <div className="num">5</div> */}
                <div className="content min label-text-wrap">
                    <div className={errorsStepA && errorsStepA.cpassword ? "inputHolder has-error" : "inputHolder"}>
                        <input type="password" placeholder=" " id="cpassword" {...registerStepA("cpassword", { validate: (value) => getValuesStepA("password") === value || "Password doesn't match" })} className="txt" aria-label="Confirm Password" />
                        <label className="tag" htmlFor="cpassword" aria-hidden>Confirm Password</label>
                        <span className="form-error" aria-live="assertive" role="alert">{(errorsStepA && errorsStepA.cpassword) && errorsStepA.cpassword.message}</span>
                    </div>
                </div>
            </li>

            <li className="form-wrap">
                {/* <div className="num">6</div> */}
                <div className="content min label-text-wrap">
                    <div className={errorsStepA && errorsStepA.phoneNumber ? "inputHolder has-error" : "inputHolder"}>
                        <div className="prefix-input">
                            <span className="prefix">+91</span>
                            <input type="text" placeholder=" " id="phoneNumber" {...registerStepA("phoneNumber", { required: true, minLength: 10, maxLength: 10 })} className="txt" onKeyDown={handleKeyPress} maxLength={10} aria-label="Mobile Number" />
                        </div>
                        <label className="tag" htmlFor="phoneNumber" aria-hidden>Mobile Number</label>
                        <span className="form-error" aria-live="assertive" role="alert">{(errorsStepA && errorsStepA.phoneNumber) && "Please provide a valid Mobile Number"}</span>
                    </div>
                </div>
            </li>
        </ol>
        <div className="btn-wrap dflex justify-content-center">
            <button type="button" className="btn" onClick={() => proceed()} disabled={savingLoader}>{savingLoader ? <svg viewBox="0 0 50 50" className="btn-loader-2"><path d="M50 25C50 11.2 38.8 0 25 0S0 11.2 0 25h4.2C4.2 13.6 13.5 4.2 25 4.2S45.8 13.6 45.8 25H50z" style={{ fill: "#fff" }}><animateTransform accumulate="none" additive="replace" attributeName="transform" attributeType="XML" calcMode="linear" dur="1s" fill="remove" from="0 25 25" repeatCount="indefinite" restart="always" to="360 25 25" type="rotate" /></path></svg> : "save and continue"}</button>
        </div>
        <Modal open={verifyemail} footer={null} closable={false} maskStyle={{backgroundColor:"rgba(0,0,0,0.75)"}}>
            <form className="verifi-popup-wrap" id="verify" onSubmit={otpSubmit(verifyOTP)}>
                <h2>Check Your Email!</h2>
                <p>We have send 6 digit confirmation code to your email.<br />
                    Please enter the code before it expires.
                </p>
                <div className="input-outer-wrap">
                    <div className="input-wrap">
                        <input type="text" {...otpRegister("opt1")} className="input-num one" maxLength={1} onInput={(e) => acceptOnlyNum(e)} onKeyUp={(e) => nextStep(1,e)} onPaste={handlepaste} />
                        <input type="text" {...otpRegister("opt2")} className="input-num two" maxLength={1} onInput={(e) => acceptOnlyNum(e)} onKeyUp={(e) => nextStep(2,e)} />
                        <input type="text" {...otpRegister("opt3")} className="input-num three" maxLength={1} onInput={(e) => acceptOnlyNum(e)} onKeyUp={(e) => nextStep(3,e)} />
                    </div>
                    <div className="txt"></div>
                    <div className="input-wrap">
                        <input type="text" {...otpRegister("opt4")} className="input-num one" maxLength={1} onInput={(e) => acceptOnlyNum(e)} onKeyUp={(e) => nextStep(4,e)} />
                        <input type="text" {...otpRegister("opt5")} className="input-num two" maxLength={1} onInput={(e) => acceptOnlyNum(e)} onKeyUp={(e) => nextStep(5,e)} />
                        <input type="text" {...otpRegister("opt6")} className="input-num three" maxLength={1} onInput={(e) => acceptOnlyNum(e)} onKeyUp={(e) => nextStep(0,e)} />
                    </div>
                </div>
                <div className="inputHolder has-error" style={{minHeight:"1px",textAlign:"center"}}><span className="form-error" aria-live="assertive" role="alert" style={{width:"100%", bottom:"-5px"}}>
                {
                    !_.isEmpty(otpError) && <>{otpError}</>
                }
                </span></div>
                {secondsLeft === 0 && <p style={{cursor:"pointer"}} onClick={() => {resendOTP()}}><b>Resend OTP</b></p>}
                {secondsLeft > 0 && <p><b>Resend Code in {secondsLeft} Seconds</b></p>}
                <p>Keep this window open while checking for your code.<br />
                    Remember to try your spam folder!
                </p>
                <div className="btn-wrap justify-content-center">
                    {/* <button className="btn">Verify</button> */}
                    <button type="submit" className="btn" disabled={verifyingOTP}>{verifyingOTP ? <svg viewBox="0 0 50 50" className="btn-loader-2"><path d="M50 25C50 11.2 38.8 0 25 0S0 11.2 0 25h4.2C4.2 13.6 13.5 4.2 25 4.2S45.8 13.6 45.8 25H50z" style={{ fill: "#fff" }}><animateTransform accumulate="none" additive="replace" attributeName="transform" attributeType="XML" calcMode="linear" dur="1s" fill="remove" from="0 25 25" repeatCount="indefinite" restart="always" to="360 25 25" type="rotate" /></path></svg> : "Verify"}</button>
                    <button type="button" className="btn red secondary" onClick={() => {setVerifyEmail(false);setSavingLoader(false)}}>Cancel</button>
                </div>
            </form>
        </Modal>
    </>
}

export default PersonalInfoForm